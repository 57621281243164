import { Log, Registry, Router } from '@lightningjs/sdk';
import { isNumber } from 'lodash';
import BasePlayer from './BasePlayer';
import { PlayerError } from '../../components/error/PlayerError';
import { getAdjustedReferringShelf, getMpid, isSleLiveGuideAllowed, setSmooth } from '../../helpers';
import UserInteractionsStoreSingleton from '../../store/UserInteractions';
import { fetchUserInteractions, setLiveWatches } from '../../store/UserInteractions/actions';
import { ENTITY_TYPES, LIVE_PLAYER_TAG, PlayerStates, PROGRAMMING_TYPES, ROUTE, ROUTE_ENTITY, } from '../../constants';
import { LiveToVodButtonStates, LiveToVodDelegate } from './delegates/LiveToVodDelegate';
import * as LiveWatches from '../../api/LiveWatches';
import PlayerStoreSingleton, { PlayerStoreEvents } from '../../store/PlayerStore/PlayerStore';
import { isEpgProgram, setEpgChannel } from '../../store/PlayerStore/actions/epg';
import { LinearSLEAnalyticsDelegate } from './delegates/analytics/LinearSLEAnalyticsDelegate';
import { WithEpg } from './hoc/WithEpg';
import LaunchDarklySingleton from '../../lib/launchDarkly/LaunchDarkly';
import LaunchDarklyFeatureFlags from '../../lib/launchDarkly/LaunchDarklyFeatureFlags';
import { WithBackToLive } from './hoc/WithBackToLive';
import { LiveStreamManager } from '../../lib/LiveStreamManager';
import { StreamLoaderErrors } from './StreamLoader/error';
import RouterUtil from '../../util/RouterUtil';
import { clearLemonade, isSingleProgram, clearState as clearStatePlayerStore, } from '../../store/PlayerStore/actions';
import { PlayerStatus } from '../../player/model/PlayerStatus';
import { CHANNEL_IDS } from '../../api/Live';
import TVPlatform from '../../lib/tv-platform';
import { ErrorType } from '../../lib/tv-platform/types';
import { usePreCheckData } from '../../store/utils/preCheckData';
import { openPlayerLoader } from '../../widgets/Modals/playerLoader/PlayerLoader';
import { FatalErrorEvent } from '../../player/model/event';
import { useProgress } from '../../components/player/PlayerControls/hooks/useProgress';
import LinearPlayerControlsV2 from '../../components/player/PlayerControls/LinearPlayerControlsV2';
import LinearPlayerControlsV1 from '../../components/player/PlayerControls/LinearPlayerControls';
import LiveToVodButton from '../../components/buttons/LiveToVodButton';
const defaultWatchDuration = 30;
class LivePlayer extends BasePlayer {
    constructor() {
        super(...arguments);
        this._timeoutRetryIndex = 0;
        this._progress = useProgress();
        this._log_tag = LIVE_PLAYER_TAG;
        this._loaded = false;
        this._liveToVodDelegate = new LiveToVodDelegate(this);
        this._programChanged = false;
        this._forceExit = false;
        this._programBoundaryChangeDelta = 20000;
        this._onProgramChange = () => {
            Log.info(this._log_tag, 'program boundary change detected');
            this._clearProgramBoundaryTimeout();
            this._clearFetchBffTimeout();
            this._programChanged = true;
            this._programOrder += 1;
            this._fetchBffTimeout = Registry.setTimeout(() => this.fetchData(), 10000); // Update EPG
        };
        this._onProgramChangeFallback = () => {
            const currentTimeUnix = Date.now();
            const programUpdateTime = 1800000; // 30 min (ms)
            const nextFetchDataTimeDiff = new Date(Math.ceil(currentTimeUnix / programUpdateTime) * programUpdateTime).getTime() -
                currentTimeUnix +
                this._programBoundaryChangeDelta;
            Log.info(this._log_tag, `Program boundary change fallback will be called in ${nextFetchDataTimeDiff} ms`);
            this._fetchBffTimeout = Registry.setTimeout(() => this.fetchData(), nextFetchDataTimeDiff);
        };
        this._updateProgramAssetInfo = () => {
            var _a, _b;
            this._controls.setup();
            (_a = this._epg) === null || _a === void 0 ? void 0 : _a.sync();
            (_b = this._analyticsDelegate) === null || _b === void 0 ? void 0 : _b.fireProgramChange();
        };
    }
    static _template() {
        const isNewPlayerDesign = LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.newPlayerTest);
        if (isNewPlayerDesign) {
            return super._template({
                Controls: { type: LinearPlayerControlsV2 },
            });
        }
        else {
            return super._template({
                Controls: { type: LinearPlayerControlsV1 },
                LiveToVodFixed: {
                    y: 900,
                    type: LiveToVodButton,
                },
            });
        }
    }
    set params(params) {
        // Allow deep linking if there's a channelId param
        if (params.channelId)
            LiveStreamManager.set(params.channelId, (params === null || params === void 0 ? void 0 : params.streamAccessName) || '', (params === null || params === void 0 ? void 0 : params.callSing) || '');
    }
    _init() {
        super._init();
        this._analyticsDelegate = new LinearSLEAnalyticsDelegate(this);
        Log.info('Launch Darkly:: Show SLE shelf', isSleLiveGuideAllowed());
        this._liveToVodFixed = this.tag('LiveToVodFixed');
    }
    async fetchData() {
        var _a;
        (_a = this._epg) === null || _a === void 0 ? void 0 : _a.fetchData();
    }
    async _onStoreEvent(data) {
        switch (data.type) {
            case PlayerStoreEvents.STREAM_OK: {
                const { program } = PlayerStoreSingleton;
                if (isSingleProgram(program) &&
                    ((program === null || program === void 0 ? void 0 : program.programmingType) === PROGRAMMING_TYPES.SLE ||
                        (program === null || program === void 0 ? void 0 : program.programmingType) === PROGRAMMING_TYPES.FER)) {
                    RouterUtil.navigateToRoute(ROUTE.watch, {
                        entity: ROUTE_ENTITY.pid,
                        value: program.pid,
                    }, { removePreviousRoute: true });
                    break;
                }
                const { stream } = PlayerStoreSingleton;
                const { callSign } = LiveStreamManager.get();
                if ((stream === null || stream === void 0 ? void 0 : stream.callSign) !== callSign)
                    LiveStreamManager.set((stream === null || stream === void 0 ? void 0 : stream.channelId) || '', (stream === null || stream === void 0 ? void 0 : stream.streamAccessName) || '', (stream === null || stream === void 0 ? void 0 : stream.callSign) || '');
                await this._load();
                break;
            }
            default:
        }
    }
    _resetStream() {
        LiveStreamManager.reset();
        PlayerStoreSingleton.dispatch(setEpgChannel(LiveStreamManager.get().channelId, '')).catch((e) => 
        // This is caught when epg data hasn't been set before
        // No need to report this to TVPlatform
        Log.error(e));
        this.fetchData();
    }
    set cancelledActivation(value) {
        if (value) {
            this._resetStream();
            this._cancelledActivation = false;
        }
        this._cancelledActivation = value;
    }
    _active() {
        super._active();
        PlayerStoreSingleton.dispatch(clearLemonade())
            .then(() => usePreCheckData())
            .then((streamDataWasPreChecked) => {
            if (!streamDataWasPreChecked) {
                this.fetchData();
            }
            else {
                openPlayerLoader(PlayerStoreSingleton.stream, PlayerStoreSingleton.program);
            }
        })
            .catch(() => this.fetchData());
        setSmooth(this.widgets.loader, 'visible', 0);
    }
    async _detach() {
        var _a;
        super._detach();
        (_a = this._liveToVodDelegate) === null || _a === void 0 ? void 0 : _a.destroy();
        this._clearProgramBoundaryTimeout();
        this._clearFetchBffTimeout();
    }
    _showLiveToVodFixed() {
        const { status } = this._liveToVodDelegate;
        const { program } = PlayerStoreSingleton;
        if (!isEpgProgram(program))
            return false;
        return (!this._liveToVodDismissed &&
            (program === null || program === void 0 ? void 0 : program.allowLiveToVodButton) &&
            status !== LiveToVodButtonStates.EMPTY_STATE);
    }
    $subscribeToLiveToVodDelegate(cb) {
        var _a;
        return (_a = this._liveToVodDelegate) === null || _a === void 0 ? void 0 : _a.events.subscribe(cb);
    }
    $onLiveToVodButtonEnter() {
        var _a;
        const { label = '', route = '' } = this._liveToVodDelegate || {};
        if (route) {
            (_a = this._analyticsDelegate) === null || _a === void 0 ? void 0 : _a.fireContentClick({
                entity: { entityType: ENTITY_TYPES.LIVE_TO_VOD },
                analytics: Object.assign(Object.assign({}, PlayerStoreSingleton.program), { textVOD: label }),
            });
            this._closeMediaPlayer();
            Router.navigate(route);
        }
    }
    loadEpg() {
        var _a;
        if (((_a = this._player) === null || _a === void 0 ? void 0 : _a.isPlaying()) || this._loaded) {
            // Refresh controls and EPG and go to Player state so UI is properly updated
            this._updateProgramAssetInfo();
        }
        else {
            this._fetchInitialStream().then(() => {
                if (!this._epg)
                    return;
                this._epg.sync();
                this._epg.visible = true;
            });
        }
    }
    loadStream() {
        var _a, _b, _c, _d, _e, _f;
        if (this.cancelledActivation) {
            this._setState(PlayerStates.Epg);
            (_b = (_a = this._epg) === null || _a === void 0 ? void 0 : _a.setContentState) === null || _b === void 0 ? void 0 : _b.call(_a);
            return;
        }
        const { program, stream } = PlayerStoreSingleton;
        if (!isEpgProgram(program))
            return;
        const referringShelf = getAdjustedReferringShelf();
        this._analytics = {
            brand: (stream === null || stream === void 0 ? void 0 : stream.brandDisplayTitle) || ((_c = program.brand) === null || _c === void 0 ? void 0 : _c.title),
            show: program.series,
            season: program.seasonNumber,
            video: program.mpxGuid,
            shelfTitle: referringShelf === null || referringShelf === void 0 ? void 0 : referringShelf.listTitle,
        };
        // Arrived from onAirNow Tile or DLS, and not an SLE, set streamAccessName
        const { accessName, channelId } = LiveStreamManager.get();
        if (!accessName && stream && 'streamAccessName' in stream) {
            LiveStreamManager.set(channelId, (stream === null || stream === void 0 ? void 0 : stream.streamAccessName) || '', (stream === null || stream === void 0 ? void 0 : stream.callSign) || '');
        }
        if (this._loaded) {
            if (!this._programChanged) {
                (_d = this._player) === null || _d === void 0 ? void 0 : _d.clearPreviousSession();
                (_e = this._analyticsDelegate) === null || _e === void 0 ? void 0 : _e.fireSessionEnd();
                this._isBuffering = false;
            }
            if (this._hasError) {
                // Recovering from error, re-attach event listeners.
                this._hasError = false;
                this._attach();
            }
        }
        else {
            this._loaded = true;
            (_f = this._analyticsDelegate) === null || _f === void 0 ? void 0 : _f.firePageLoad();
        }
        this._load();
    }
    _fetchInitialStream() {
        return LiveStreamManager.update().catch(() => {
            this._setErrorState(PlayerError.UNKNOWN, new FatalErrorEvent({
                description: 'Unable to find stream in Episode Guide',
                code: '404',
            }));
        });
    }
    $playOrPause() {
        var _a, _b;
        const name = ((_a = this._player) === null || _a === void 0 ? void 0 : _a.isPlaying()) ? 'Live Pause' : 'Live Play';
        (_b = this._analyticsDelegate) === null || _b === void 0 ? void 0 : _b.fireClick(name);
    }
    async _startStream() {
        await super._startStream();
        this._setProgramBoundaryTimeout();
        if (this._programChanged) {
            // Should be removed when migrate to live guide v2
            this._programChanged = false;
            return this._updateProgramAssetInfo();
        }
        this._liveToVodDismissed = false;
        this._liveToVodDelegate.reset();
        this._liveToVodDelegate.sync();
        const livePlayerWatchDurationValue = LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.livePlayerWatchDuration);
        const timeOutValue = livePlayerWatchDurationValue
            ? livePlayerWatchDurationValue * 1000
            : defaultWatchDuration * 1000;
        Registry.setTimeout(async () => {
            var _a, _b;
            try {
                const result = await LiveWatches.post(getMpid(), PlayerStoreSingleton.stream);
                if (result.data) {
                    const { attributes, id, relationships } = result.data;
                    UserInteractionsStoreSingleton.dispatch(setLiveWatches({
                        dateTimeWatched: attributes === null || attributes === void 0 ? void 0 : attributes.streamDateTimeWatched,
                        watchId: id,
                        brandId: (_b = (_a = relationships === null || relationships === void 0 ? void 0 : relationships.brand) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.id,
                        brandMachineName: attributes === null || attributes === void 0 ? void 0 : attributes.brandMachineName,
                        nationalBroadcastType: attributes === null || attributes === void 0 ? void 0 : attributes.nationalBroadcastType,
                    }));
                    await UserInteractionsStoreSingleton.dispatch(fetchUserInteractions(true));
                }
            }
            catch (e) {
                TVPlatform.reportError({
                    type: ErrorType.NETWORK,
                    code: LIVE_PLAYER_TAG,
                    description: 'An error occurred fetching live watches',
                });
            }
        }, timeOutValue);
    }
    _clearProgramBoundaryTimeout() {
        if (this._programBoundaryTimeout) {
            Registry.clearTimeout(this._programBoundaryTimeout);
            this._programBoundaryTimeout = null;
        }
    }
    _clearFetchBffTimeout() {
        if (this._fetchBffTimeout) {
            Registry.clearTimeout(this._fetchBffTimeout);
            this._fetchBffTimeout = null;
        }
    }
    /*
     * Set a timeout equal to the remaining seconds to check for program boundary if scte35 tags aren't working.
     * This timeout is cleared if the scte35 tags detect a program boundary change.
     */
    _setProgramBoundaryTimeout() {
        this._clearProgramBoundaryTimeout();
        this._clearFetchBffTimeout();
        const { stream, program } = PlayerStoreSingleton;
        const startTime = (program === null || program === void 0 ? void 0 : program.startTime) || (stream === null || stream === void 0 ? void 0 : stream.startTime);
        const endTime = (program === null || program === void 0 ? void 0 : program.endTime) || (stream === null || stream === void 0 ? void 0 : stream.endTime);
        if (!startTime || !endTime)
            return;
        const startTimeUnix = isNumber(startTime) ? startTime : Date.parse(startTime);
        const endTimeUnix = isNumber(endTime) ? endTime : Date.parse(endTime);
        if (startTimeUnix === endTimeUnix)
            return this._onProgramChangeFallback();
        const currentTimeUnix = Date.now();
        const duration = endTimeUnix - startTimeUnix;
        const millisecondsRemaining = duration - (currentTimeUnix - startTimeUnix);
        if (millisecondsRemaining > 0) {
            const timeout = millisecondsRemaining + this._programBoundaryChangeDelta;
            Log.info(this._log_tag, `exec program boundary timeout in ${timeout} ms`);
            this._programBoundaryTimeout = Registry.setTimeout(this._onProgramChange, timeout);
            // Sometimes BFF data is just wrong, keeping internal counter to retry just once
        }
        else if (this._timeoutRetryIndex === 0) {
            this._timeoutRetryIndex += 1;
            this.fetchData();
        }
        else {
            this._timeoutRetryIndex = 0;
        }
    }
    /**
     Disable ad indicator for live because total number of ads is not included in the live content's ads manifest
     */
    _updateAdIndicator() {
        this._adIndicator.patch({ alpha: 0 });
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    _hideAdIndicator() { }
    async _onErrorModalBack() {
        var _a, _b, _c;
        if (LiveStreamManager.get().accessName !== CHANNEL_IDS.nbcnews &&
            ((_a = this._player) === null || _a === void 0 ? void 0 : _a.status) === PlayerStatus.UNKNOWN) {
            await PlayerStoreSingleton.dispatch(clearStatePlayerStore());
            Router.back();
        }
        else if (!((_b = this._player) === null || _b === void 0 ? void 0 : _b.isPlaying())) {
            (_c = this._player) === null || _c === void 0 ? void 0 : _c.play();
        }
    }
    _startNewTrackingSession() {
        if (!this._programChanged)
            super._startNewTrackingSession();
    }
    _openErrorModal(res) {
        var _a, _b, _c, _d;
        if (((_a = res === null || res === void 0 ? void 0 : res.error) === null || _a === void 0 ? void 0 : _a.detail) === StreamLoaderErrors.GEO) {
            res.error.data = {
                description: this._programChanged ? 'program change geo restriction' : 'geo code error',
                code: ((_c = (_b = res.error) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.code) || ((_d = res.error) === null || _d === void 0 ? void 0 : _d.data),
            };
        }
        super._openErrorModal(res);
    }
}
export default WithBackToLive(WithEpg(LivePlayer));
